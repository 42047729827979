import type { BaseError } from "@lib/axios";
import {
  DefaultOptions,
  QueryClient,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import type { AxiosError } from "axios";

import { isTesting } from "@/test/utils";

const queryConfig: DefaultOptions = {
  queries: {
    cacheTime: isTesting ? Infinity : 300,
    refetchOnWindowFocus: false,
    retry: false,
  },
};

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
});

export function composeCallbackHandlers<FnType extends (...args: any) => any>(
  originalHandler?: FnType,
  ourHandler?: FnType,
) {
  return function handleEvent(...args: Parameters<FnType>) {
    originalHandler?.(args);

    return ourHandler?.(args);
  };
}

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<
  ReturnType<FnType>
>;

export type QueryConfig<
  QueryFnType extends (...args: any) => any,
  QuerySelectFn extends (...args: any) => any = QueryFnType,
> = Omit<
  UseQueryOptions<
    ExtractFnReturnType<QueryFnType>,
    AxiosError<BaseError>,
    ExtractFnReturnType<QuerySelectFn>
  >,
  "queryKey" | "queryFn"
>;

export type UseQuery<
  T extends (...args: any) => any,
  U extends (...args: any) => any = T,
> = {
  config?: QueryConfig<T, U>;
};

export type InfiniteQueryConfig<
  QueryFnType extends (...args: any) => any,
  QuerySelectFn extends (...args: any) => any = QueryFnType,
> = Omit<
  UseInfiniteQueryOptions<
    ExtractFnReturnType<QueryFnType>,
    AxiosError<BaseError>,
    ExtractFnReturnType<QuerySelectFn>,
    ExtractFnReturnType<QueryFnType>
  >,
  "queryKey" | "queryFn"
>;

export type UseInfiniteQuery<
  T extends (...args: any) => any,
  U extends (...args: any) => any = T,
> = {
  config?: InfiniteQueryConfig<T, U>;
};

export type MutationConfig<MutationFnType extends (...args: any) => any> =
  UseMutationOptions<
    ExtractFnReturnType<MutationFnType>,
    AxiosError<BaseError>,
    Parameters<MutationFnType>[0] extends undefined
      ? void
      : Parameters<MutationFnType>[0]
  >;

export type UseMutation<T extends (...args: any) => any> = {
  config?: MutationConfig<T>;
};

import { AceJwt } from "@/types";

/**
 * Decodes a jwt and return its payload as an object.
 *
 * @param {string} jwt - the jwt to parse
 * @returns {object}
 */
export const parseJwt = (jwt: string) => {
  if (jwt) return JSON.parse(atob(jwt.split(".")[1]));
};

/**
 * Checks if a JWT is expired.
 *
 * @param {string} jwt - the jwt to check
 * @returns {boolean} - true if the jwt is about to expire; false otherwise
 */
export const isJwtExpired = (jwt?: string): boolean => {
  if (!jwt) throw new Error("Jwt is not provided");

  const { exp, iat } = parseJwt(jwt) as AceJwt;

  const currentSystemTime = Math.floor(Date.now() / 1000);
  const jwtLifespan = exp - iat;
  const jwtElapsedTime = currentSystemTime - iat;

  return jwtElapsedTime >= jwtLifespan;
};

/**
 * Get the value from the environment
 *
 * @param name
 * @param defaultValue
 */
type EnvWindow = Window & { _env_?: any };

export const env = (name = "", defaultValue = "") => {
  const envVars = (window as EnvWindow)._env_ || process.env;
  return name !== "" ? envVars[name] : envVars || defaultValue;
};

export const getEnv = env;

export default env;

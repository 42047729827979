import { queryClient } from "@lib/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import {
  type Matcher,
  type Queries,
  type RenderOptions,
  type RenderResult,
  act,
  queries,
  render as rtlRender,
  waitForElementToBeRemoved,
} from "@testing-library/react";
import { BrowserRouter as Router } from "react-router-dom";

import { ICountryFields } from "@/types/contentful";

import { AccountType, Classification, IUserContext, KycStatus } from "../types";

export const render = (
  component: React.ReactElement,
  renderOptions?: RenderOptions,
) => {
  return {
    ...rtlRender(component, {
      wrapper: ({ children }) => (
        <QueryClientProvider client={queryClient}>
          <Router>{children}</Router>
        </QueryClientProvider>
      ),
      ...renderOptions,
    }),
  };
};

export const isTesting = process.env.NODE_ENV === "test";

export function nextTick(): Promise<void> {
  return act(
    () => new Promise((resolve) => requestAnimationFrame(() => resolve())),
  );
}

export async function sleep(ms = 16): Promise<void> {
  await act(() => new Promise((resolve) => setTimeout(resolve, ms)));
  await nextTick();
}

export async function waitForLoadersToBeRemoved<
  Q extends Queries = typeof queries,
>(
  root: RenderResult,
  queryType: keyof Pick<
    Q,
    "queryAllByTestId" | "queryAllByText" | "queryAllByRole"
  > = "queryAllByTestId",
  text: Matcher = /loading/i,
): Promise<void> {
  const loadingElements = root[queryType](text);

  if (loadingElements.length) {
    await waitForElementToBeRemoved(loadingElements, { timeout: 10000 });
  }
}

export const getInitialContext = (
  context?: Partial<IUserContext>,
): IUserContext => ({
  classification: Classification.EMPTY,
  clientCategorisation: null,
  country: {
    blacklisted: false,
    name: "United Kingdom",
  } as ICountryFields,
  created: "",
  email: "test@test.com",
  examClassification: null,
  forename: "Test",
  jwt: "jwt",
  kycStatus: KycStatus.EMPTY,
  mfaExempt: true,
  name: "Test",
  setUser: jest.fn(),
  setVisitor: jest.fn(),
  signOut: jest.fn(),
  surname: "Test",
  tempjwt: "Test",
  type: AccountType.PERSONAL,
  ...context,
});

import { IUserContext } from "@/types";

const USER_LABEL = "ace-user";

const storage = {
  clearUser: (): void => {
    window.sessionStorage.setItem(
      USER_LABEL,
      JSON.stringify({ jwt: "", name: "" }),
    );
    window.dispatchEvent(new Event("storage"));
  },
  getToken: (): string | undefined => {
    try {
      const user: Partial<IUserContext> = JSON.parse(
        window.sessionStorage.getItem(USER_LABEL) as string,
      );

      return user?.jwt || user?.tempjwt;
    } catch {
      return undefined;
    }
  },
  getUser: (): Partial<IUserContext> | null => {
    try {
      return JSON.parse(window.sessionStorage.getItem(USER_LABEL) as string);
    } catch {
      return null;
    }
  },
  setUser: (user: Partial<IUserContext>) => {
    window.sessionStorage.setItem(USER_LABEL, JSON.stringify(user));
    window.dispatchEvent(new Event("storage"));
  },
};

export { storage };

import { KycStatus } from "../types";
import routes from "./routes";
import { isApprovedCorporateAccount } from "./rules";
import { TLink } from "./ui/common/footer/Footer";

export const HUBSPOT_URL = "https://archax.com";

export const LOCAL_STORAGE_KEY_ACKNOWLEDGED_COOLING_OFF_PERIOD =
  "acknowledgedCoolingOffPeriod";

/**
 * Valid MIME patterns based on the valid extensions
 *
 * @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
 */
export const VALID_MIME_PATTERNS = {
  "application/pdf": "25504446",
  "image/bmp": "424d",
  "image/jpeg": "ffd8",
  "image/png": "89504e47",
  "image/tiff": "49492a0",
};

// Keyboard codes
export const ENTER_KEY = "Enter";
export const ENTER_KEYCODE = 13;

export const UP_ARROW_KEY = "ArrowUp";
export const DOWN_ARROW_KEY = "ArrowDown";
export const ESCAPE_KEY = "Escape";
export const TAB_KEY = "Tab";

// value used to offset the scrolling to improve the alignment
// when moving to the next or previous item
export const offsetValue = 10;

// KYC role
export const KYC_ROLE = "kyc";

// KYC questionnaire results
export const KYC_QUESTIONS_PASS = "pass";
export const KYC_QUESTIONS_FAIL = "fail";

// KYC completed statuses for the individual user
export const KYC_COMPLETED_INDIVIDUAL = [
  KycStatus.GENERAL_COMPLETED,
  KycStatus.PERSONAL_QUANTITATIVE_1,
  KycStatus.PERSONAL_QUANTITATIVE_2,
  KycStatus.PERSONAL_QUALITATIVE_1,
];

// KYC completed statuses for the corporate user
export const KYC_COMPLETED_CORPORATE = [
  KycStatus.GENERAL_COMPLETED,
  KycStatus.CORPORATE_QUALITATIVE_1,
];

// KYC completed statuses
export const KYC_COMPLETED_STATUSES = [
  ...new Set([...KYC_COMPLETED_INDIVIDUAL, ...KYC_COMPLETED_CORPORATE]),
];

export const UNREGULATED_PRODUCT_ID = 2;

// Submit types
export enum SubmitType {
  UPDATE_INVEST = "invest",
  UPDATE_PREFERENCE = "preference",
  UPDATE_ACCOUNT_TYPE = "account-type",
}
export const UPDATE_INVEST = "invest";
export const UPDATE_PREFERENCE = "preference";
export const UPDATE_ACCOUNT_TYPE = "account-type";

//Modal statuses
export const IDENTIFIED_AS_PROF_MODAL = "identifiedAsProfessionalModal";
export const PASSED_AML_KYC_MODAL = "passedAMLKYCModal";
export const SUBMITTED_AVAILABLE_SERVICES_PAGE = "availableServicesPage";

// Default error message
export const DEFAULT_ERROR_MESSAGE =
  "Sorry we were unable to process your request, if this persists please contact support@archax.com.";

// Custody disclaimer message
export const CUSTODY_DISCLAIMER =
  "*Admittances are only available for corporate accounts, not personal accounts";

export const JWT_EXPIRED_ERROR = "JWT has expired";

// FILE TYPES
export const ACCEPTED_FILES_STRING = ".png,.pdf,.jpg,.jpeg,.bmp,.tiff,.tif";

// DOCUMENT TYPES
export const DOCUMENT_TYPE_ADDRESS = "address";

export enum DOCUMENT_TYPES {
  DOCUMENT_TYPE_ADDRESS = "address",
}

// PROOF OF IDENTITY TYPES
export const DRIVING_LICENCE = "drivingLicence";
export const NATIONAL_ID_CARD = "nationalIDCard";
export const PASSPORT = "passport";
export const RESIDENCE_PERMIT = "residencePermit";
export const VISA = "visa";

// PROOF OF ADDRESS TYPES
export const BANK_STATEMENT = "bankStatement";
export const COUNCIL_TAX_BILL = "councilTaxBill";
export const HMRC = "hmrc";
export const MORTGAGE_STATEMENT = "mortgageStatement";
export const RENT_CARD = "rentCard";
export const SOLICITORS_LETTER = "solicitorsLetter";
export const UK_DRIVING_LICENCE = "ukDrivingLicence";
export const UTILITY_BILL = "utilityBill";

// OLD KYC STATUSES
// PLEASE REMOVE ONCE ALL KYC FE TICKETS COMPLETED
export const PERSONAL_APPROVED = 13;
export const PERSONAL_REJECTED = 11;

export const CORPORATE_APPROVED = 14;
export const CORPORATE_REJECTED = 7;

// VIEW BREAKPOINTS
export const CORPORATE_HEADER_OFFSET = 100;
export const DASHBOARD_HEADER_OFFSET = 275;
export const MOBILE_BREAKPOINT = 1200;
export const MOBILE_CORPORATE_OFFSET = -50;
export const MOBILE_DASHBOARD_OFFSET = 65;
export const MOBILE_HEIGHTBREAKPOINT = 650;

// PRODUCTS
export const UNREGULATED_PRODUCTS = 2;

export interface IHeaderData {
  basicNavs: Array<IBasicNav>;
  basicNavsMobile: Array<IBasicNav>;
  corporateAccountOptions: Array<IAccountOptions>;
  main: Array<IMain>;
  personalAccountOptions: Array<IAccountOptions>;
  secondary: Array<ISecondary>;
  userNavs: Array<IUserNavs>;
}

export interface IBasicNav {
  href?: string;
  name: string;
}

export interface IAccountOptions {
  href?: string;
  name: string;
  type?: string;
}

export interface IMain {
  href: string;
  name: string;
}

export interface ISecondary {
  color: string;
  href?: string;
  icon?: string;
  name: string;
}

export interface IUserNavs {
  href?: string;
  func?: (data) => boolean;
  text: string;
  type: string;
}
export enum UserTypes {
  PUBLIC_USER = "publicUser",
  REGISTRED_USER = "registredUser",
  CLIENT_USER = "clientUser",
}

export enum InvestmentStatuses {
  SALE_OPEN = "Sale Open",
  SALE_CLOSED = "Sale Closed",
  COMING_SOON = "Coming Soon",
}

// data for the header of the site
export const headerData: IHeaderData = {
  basicNavs: [{ href: routes.home, name: "Home" }, { name: "Login" }],

  basicNavsMobile: [{ name: "Login" }, { href: routes.home, name: "Home" }],

  corporateAccountOptions: [
    {
      href: routes.landingCorporate,
      name: "Account",
      type: "link",
    },
    {
      href: routes.resetPassword,
      name: "Reset password",
      type: "button",
    },
    {
      href: routes.mfa,
      name: "Set up 2FA",
      type: "link",
    },
    {
      href: routes.download,
      name: "Download",
      type: "link",
    },
    {
      href: routes.documentLibrary,
      name: "Document Library",
      type: "link",
    },
    {
      name: "Logout",
    },
  ],

  main: [
    { href: routes.invest, name: "Invest" },
    { href: routes.trade, name: "Trade" },
    { href: routes.admit, name: "Admit" },
    { href: routes.custody, name: "Custody" },
    { href: routes.news, name: "News" },
    { href: routes.contactUs, name: "Contact Us" },
  ],

  personalAccountOptions: [
    {
      href: routes.dashboard,
      name: "Account",
      type: "link",
    },
    {
      href: routes.resetPassword,
      name: "Reset password",
      type: "button",
    },
    {
      href: routes.mfa,
      name: "Set up 2FA",
      type: "link",
    },
    {
      href: routes.download,
      name: "Download",
      type: "link",
    },
    {
      href: routes.documentLibrary,
      name: "Document Library",
      type: "link",
    },
    {
      name: "Logout",
    },
  ],

  secondary: [
    { color: "white", href: routes.home, name: "Register" },
    { color: "primary", icon: "user", name: "Login" },
  ],
  userNavs: [
    { href: "", text: "Account", type: "link" },
    { href: "/register-issuance", text: "Issuance Registration", type: "link" },
    { href: routes.mfa, text: "Set 2FA", type: "link" },
    {
      func: isApprovedCorporateAccount,
      href: routes.inviteUser,
      text: "Invite new user",
      type: "link",
    },
    { text: "Reset password", type: "button" },
  ],
};

// data for the footer of the site
export const footerData: { links: TLink[] } = {
  links: [
    {
      dataTestId: "footer-document-library-link",
      href: routes.documentLibrary,
      name: "Document Library",
    },
    {
      dataTestId: "footer-system-status-link",
      external: true,
      href: `${HUBSPOT_URL}/system-status`,
      name: "System Status",
    },
    {
      dataTestId: "footer-api-link",
      href: routes.apiDocs,
      name: "API",
    },
    {
      dataTestId: "footer-careers-link",
      external: true,
      href: "https://careers.archax.com/jobs",
      name: "Careers",
    },
    {
      dataTestId: "footer-regulatory-permissions-link",
      external: true,
      href: `${HUBSPOT_URL}/regulatory-permissions`,
      name: "Regulatory Permissions",
    },
    {
      dataTestId: "footer-privacy-policy-link",
      external: true,
      href: `${HUBSPOT_URL}/privacy-policies`,
      name: "Privacy Policy",
    },
    {
      dataTestId: "footer-terms-and-conditions-link",
      external: true,
      href: `${HUBSPOT_URL}/terms`,
      name: "Terms and Conditions",
    },
  ],
};

// list of all Europenian Country 2 letter ISOs
export const euList = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
];

// list of Country 2 letter ISO who follow the ISO date format
export const standardISODate = [
  "IN",
  "RU",
  "VN",
  "DE",
  "IR",
  "FR",
  "GB",
  "MM",
  "ES",
  "PL",
  "UZ",
  "AF",
  "NP",
  "CM",
  "LK",
  "RO",
  "US",
  "KE",
  "GH",
  "CN",
  "JP",
  "KR",
  "CA",
  "KP",
  "TW",
  "HU",
  "MN",
  "LT",
  "BT",
];

// all available icons
// edit this when adding / deleting icons
export const icons = [
  "caret-down",
  "caret-left",
  "caret-right",
  "caret-up",
  "home",
  "bars",
  "user",
  "users",
  "user-tie",
  "user-circle",
  "linkedin",
  "twitter",
  "telegram",
  "info",
  "pencil",
  "upload",
  "download",
  "check",
  "eye",
  "journal",
  "handshake",
  "globe",
  "newspaper",
  "warning",
  "times",
  "times-circle",
  "plus",
  "plus-plain",
  "print",
  "left-arrow",
  "right-arrow",
  "key",
  "star",
  "thumbs-up",
  "clock",
  "signin",
  "signout",
  "mobile",
  "minus",
  "question",
  "up-arrow",
  "down-arrow",
  "ellipsis",
  "calendar",
  "ext-link",
  "asterisk",
  "medium",
  "facebook",
  "next",
  "down",
  "add",
  "subtract",
  "exclamation",
  "trash",
];

export type CountryChoices = {
  id: string;
  label: string;
};

export const countryChoices: CountryChoices[] = [
  // A
  { id: "AF", label: "Afghanistan" },
  { id: "AX", label: "Aland Islands" },
  { id: "AL", label: "Albania" },
  { id: "DZ", label: "Algeria" },
  { id: "AS", label: "American Samoa" },
  { id: "AD", label: "Andorra" },
  { id: "AO", label: "Angola" },
  { id: "AI", label: "Anguilla" },
  { id: "AQ", label: "Antarctica" },
  { id: "AG", label: "Antigua and Barbuda" },
  { id: "AR", label: "Argentina" },
  { id: "AM", label: "Armenia" },
  { id: "AW", label: "Aruba" },
  { id: "AU", label: "Australia" },
  { id: "AT", label: "Austria" },
  { id: "AZ", label: "Azerbaijan" },
  // B
  { id: "BS", label: "Bahamas" },
  { id: "BH", label: "Bahrain" },
  { id: "BD", label: "Bangladesh" },
  { id: "BB", label: "Barbados" },
  { id: "BY", label: "Belarus" },
  { id: "BE", label: "Belgium" },
  { id: "BZ", label: "Belize" },
  { id: "BJ", label: "Benin" },
  { id: "BM", label: "Bermuda" },
  { id: "BT", label: "Bhutan" },
  { id: "BO", label: "Bolivia" },
  { id: "BA", label: "Bosnia and Herzegovina" },
  { id: "BW", label: "Botswana" },
  { id: "BV", label: "Bouvet Island" },
  { id: "BR", label: "Brazil" },
  { id: "VG", label: "British Virgin Islands" },
  { id: "IO", label: "British Indian Ocean Territory" },
  { id: "BN", label: "Brunei Darussalam" },
  { id: "BG", label: "Bulgaria" },
  { id: "BF", label: "Burkina Faso" },
  { id: "BI", label: "Burundi" },

  // C
  { id: "KH", label: "Cambodia" },
  { id: "CM", label: "Cameroon" },
  { id: "CA", label: "Canada" },
  { id: "CV", label: "Cape Verde" },
  { id: "KY", label: "Cayman Islands" },
  { id: "CF", label: "Central African Republic" },
  { id: "TD", label: "Chad" },
  { id: "CL", label: "Chile" },
  { id: "CN", label: "China" },
  { id: "HK", label: "Hong Kong" },
  { id: "MO", label: "Macao" },
  { id: "CX", label: "Christmas Island" },
  { id: "CC", label: "Cocos (Keeling) Islands" },
  { id: "CO", label: "Colombia" },
  { id: "KM", label: "Comoros" },
  { id: "CG", label: "Congo (Brazzaville)" },
  { id: "CD", label: "Congo, (Kinshasa)" },
  { id: "CK", label: "Cook Islands" },
  { id: "CR", label: "Costa Rica" },
  { id: "CI", label: "Côte d'Ivoire" },
  { id: "HR", label: "Croatia" },
  { id: "CU", label: "Cuba" },
  { id: "CY", label: "Cyprus" },
  { id: "CZ", label: "Czech Republic" },

  // D
  { id: "DK", label: "Denmark" },
  { id: "DJ", label: "Djibouti" },
  { id: "DM", label: "Dominica" },
  { id: "DO", label: "Dominican Republic" },

  // E
  { id: "EC", label: "Ecuador" },
  { id: "EG", label: "Egypt" },
  { id: "SV", label: "El Salvador" },
  { id: "GQ", label: "Equatorial Guinea" },
  { id: "ER", label: "Eritrea" },
  { id: "EE", label: "Estonia" },
  { id: "ET", label: "Ethiopia" },

  //F
  { id: "FK", label: "Falkland Islands (Malvinas)" },
  { id: "FO", label: "Faroe Islands" },
  { id: "FJ", label: "Fiji" },
  { id: "FI", label: "Finland" },
  { id: "FR", label: "France" },
  { id: "GF", label: "French Guiana" },
  { id: "PF", label: "French Polynesia" },
  { id: "TF", label: "French Southern Territories" },

  // G
  { id: "GA", label: "Gabon" },
  { id: "GM", label: "Gambia" },
  { id: "GE", label: "Georgia" },
  { id: "DE", label: "Germany" },
  { id: "GH", label: "Ghana" },
  { id: "GI", label: "Gibraltar" },
  { id: "GR", label: "Greece" },
  { id: "GL", label: "Greenland" },
  { id: "GD", label: "Grenada" },
  { id: "GP", label: "Guadeloupe" },
  { id: "GU", label: "Guam" },
  { id: "GT", label: "Guatemala" },
  { id: "GG", label: "Guernsey" },
  { id: "GN", label: "Guinea" },
  { id: "GW", label: "Guinea-Bissau" },
  { id: "GY", label: "Guyana" },

  // H
  { id: "HT", label: "Haiti" },
  { id: "HM", label: "Heard and Mcdonald Islands" },
  { id: "VA", label: "Holy See (Vatican City State)" },
  { id: "HN", label: "Honduras" },
  { id: "HU", label: "Hungary" },

  // I
  { id: "IS", label: "Iceland" },
  { id: "IN", label: "India" },
  { id: "ID", label: "Indonesia" },
  { id: "IR", label: "Iran, Islamic Republic of" },
  { id: "IQ", label: "Iraq" },
  { id: "IE", label: "Ireland" },
  { id: "IM", label: "Isle of Man" },
  { id: "IL", label: "Israel" },
  { id: "IT", label: "Italy" },

  // J
  { id: "JM", label: "Jamaica" },
  { id: "JP", label: "Japan" },
  { id: "JE", label: "Jersey" },
  { id: "JO", label: "Jordan" },

  // K
  { id: "KZ", label: "Kazakhstan" },
  { id: "KE", label: "Kenya" },
  { id: "KI", label: "Kiribati" },
  { id: "KP", label: "Korea (North)" },
  { id: "KR", label: "Korea (South)" },
  { id: "KW", label: "Kuwait" },
  { id: "KG", label: "Kyrgyzstan" },

  // L
  { id: "LA", label: "Lao PDR" },
  { id: "LV", label: "Latvia" },
  { id: "LB", label: "Lebanon" },
  { id: "LS", label: "Lesotho" },
  { id: "LR", label: "Liberia" },
  { id: "LY", label: "Libya" },
  { id: "LI", label: "Liechtenstein" },
  { id: "LT", label: "Lithuania" },
  { id: "LU", label: "Luxembourg" },

  // M
  { id: "MK", label: "Macedonia, Republic of" },
  { id: "MG", label: "Madagascar" },
  { id: "MW", label: "Malawi" },
  { id: "MY", label: "Malaysia" },
  { id: "MV", label: "Maldives" },
  { id: "ML", label: "Mali" },
  { id: "MT", label: "Malta" },
  { id: "MH", label: "Marshall Islands" },
  { id: "MQ", label: "Martinique" },
  { id: "MR", label: "Mauritania" },
  { id: "MU", label: "Mauritius" },
  { id: "YT", label: "Mayotte" },
  { id: "MX", label: "Mexico" },
  { id: "FM", label: "Micronesia, Federated States of" },
  { id: "MD", label: "Moldova" },
  { id: "MC", label: "Monaco" },
  { id: "MN", label: "Mongolia" },
  { id: "ME", label: "Montenegro" },
  { id: "MS", label: "Montserrat" },
  { id: "MA", label: "Morocco" },
  { id: "MZ", label: "Mozambique" },
  { id: "MM", label: "Myanmar" },

  // N
  { id: "NA", label: "Namibia" },
  { id: "NR", label: "Nauru" },
  { id: "NP", label: "Nepal" },
  { id: "NL", label: "Netherlands" },
  { id: "AN", label: "Netherlands Antilles" },
  { id: "NC", label: "New Caledonia" },
  { id: "NZ", label: "New Zealand" },
  { id: "NI", label: "Nicaragua" },
  { id: "NE", label: "Niger" },
  { id: "NG", label: "Nigeria" },
  { id: "NU", label: "Niue" },
  { id: "NF", label: "Norfolk Island" },
  { id: "MP", label: "Northern Mariana Islands" },
  { id: "NO", label: "Norway" },

  // O
  { id: "OM", label: "Oman" },

  // P
  { id: "PK", label: "Pakistan" },
  { id: "PW", label: "Palau" },
  { id: "PS", label: "Palestinian Territory" },
  { id: "PA", label: "Panama" },
  { id: "PG", label: "Papua New Guinea" },
  { id: "PY", label: "Paraguay" },
  { id: "PE", label: "Peru" },
  { id: "PH", label: "Philippines" },
  { id: "PN", label: "Pitcairn" },
  { id: "PL", label: "Poland" },
  { id: "PT", label: "Portugal" },
  { id: "PR", label: "Puerto Rico" },

  // Q
  { id: "QA", label: "Qatar" },

  // R
  { id: "RE", label: "Réunion" },
  { id: "RO", label: "Romania" },
  { id: "RU", label: "Russian Federation" },
  { id: "RW", label: "Rwanda" },

  // S
  { id: "BL", label: "Saint-Barthélemy" },
  { id: "SH", label: "Saint Helena" },
  { id: "KN", label: "Saint Kitts and Nevis" },
  { id: "LC", label: "Saint Lucia" },
  { id: "MF", label: "Saint-Martin (French part)" },
  { id: "PM", label: "Saint Pierre and Miquelon" },
  { id: "VC", label: "Saint Vincent and Grenadines" },
  { id: "WS", label: "Samoa" },
  { id: "SM", label: "San Marino" },
  { id: "ST", label: "Sao Tome and Principe" },
  { id: "SA", label: "Saudi Arabia" },
  { id: "SN", label: "Senegal" },
  { id: "RS", label: "Serbia" },
  { id: "SC", label: "Seychelles" },
  { id: "SL", label: "Sierra Leone" },
  { id: "SG", label: "Singapore" },
  { id: "SK", label: "Slovakia" },
  { id: "SI", label: "Slovenia" },
  { id: "SB", label: "Solomon Islands" },
  { id: "SO", label: "Somalia" },
  { id: "ZA", label: "South Africa" },
  { id: "GS", label: "South Georgia and the South Sandwich Islands" },
  { id: "SS", label: "South Sudan" },
  { id: "ES", label: "Spain" },
  { id: "LK", label: "Sri Lanka" },
  { id: "SD", label: "Sudan" },
  { id: "SR", label: "Suriname" },
  { id: "SJ", label: "Svalbard and Jan Mayen Islands" },
  { id: "SZ", label: "Swaziland" },
  { id: "SE", label: "Sweden" },
  { id: "CH", label: "Switzerland" },
  { id: "SY", label: "Syrian Arab Republic (Syria)" },

  // T
  { id: "TW", label: "Taiwan, Republic of China" },
  { id: "TJ", label: "Tajikistan" },
  { id: "TZ", label: "Tanzania, United Republic of" },
  { id: "TH", label: "Thailand" },
  { id: "TL", label: "Timor-Leste" },
  { id: "TG", label: "Togo" },
  { id: "TK", label: "Tokelau" },
  { id: "TO", label: "Tonga" },
  { id: "TT", label: "Trinidad and Tobago" },
  { id: "TN", label: "Tunisia" },
  { id: "TR", label: "Turkey" },
  { id: "TM", label: "Turkmenistan" },
  { id: "TC", label: "Turks and Caicos Islands" },
  { id: "TV", label: "Tuvalu" },

  // U
  { id: "UG", label: "Uganda" },
  { id: "UA", label: "Ukraine" },
  { id: "AE", label: "United Arab Emirates" },
  { id: "GB", label: "United Kingdom" },
  { id: "US", label: "United States of America" },
  { id: "UM", label: "US Minor Outlying Islands" },
  { id: "UY", label: "Uruguay" },
  { id: "UZ", label: "Uzbekistan" },

  // V
  { id: "VU", label: "Vanuatu" },
  { id: "VE", label: "Venezuela (Bolivarian Republic)" },
  { id: "VN", label: "Viet Nam" },
  { id: "VI", label: "Virgin Islands, US" },

  // W
  { id: "WF", label: "Wallis and Futuna Islands" },
  { id: "EH", label: "Western Sahara" },

  // Y-Z
  { id: "YE", label: "Yemen" },
  { id: "ZM", label: "Zambia" },
  { id: "ZW", label: "Zimbabwe" },
];

export const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export enum NationalIdentifierAndPriorityCode {
  CCPT = "CCPT",
  CUST = "CUST",
  NIDN = "NIDN",
  SOSE = "SOSE",
  TXID = "TXID",
}

export interface INationalIdentifierAndPriorityItem {
  countryName: string;
  priority1Identifier: string;
  code1: NationalIdentifierAndPriorityCode;
  priority2Identifier?: string;
  code2?: NationalIdentifierAndPriorityCode;
  priority3Identifier?: string;
  code3?: NationalIdentifierAndPriorityCode;
}

export const nationalIdentifierAndPriority = {
  AT: {
    code1: NationalIdentifierAndPriorityCode.CCPT,
    countryName: "Austria",
    priority1Identifier: "CONCAT",
  },
  BE: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Belgium",
    priority1Identifier:
      "Belgian National Number\n" +
      "(Numéro de registre national –\n" +
      "Rijksregisternummer)",
    priority2Identifier: "CONCAT",
  },
  BG: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Bulgaria",
    priority1Identifier: "Bulgarian Personal Number",
    priority2Identifier: "CONCAT",
  },
  CY: {
    code1: NationalIdentifierAndPriorityCode.CCPT,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Cyprus",
    priority1Identifier: "National Passport Number",
    priority2Identifier: "CONCAT",
  },
  CZ: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CCPT,
    code3: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Czech Republic",
    priority1Identifier: "National identification number\n" + "(Rodné číslo) ",
    priority2Identifier: "Passport Number",
    priority3Identifier: "CONCAT",
  },
  DE: {
    code1: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Germany",
    priority1Identifier: "CONCAT",
  },
  DK: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Denmark",
    priority1Identifier:
      "Personal identity code\n" + "10 digits alphanumerical:\n" + "DDMMYYXXXX",
    priority2Identifier: "CONCAT",
  },
  EE: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    countryName: "Estonia",
    priority1Identifier:
      "Estonian Personal Identification\n" + "Code\n" + "(Isikukood)",
  },
  ES: {
    code1: NationalIdentifierAndPriorityCode.TXID,
    countryName: "Spain",
    priority1Identifier:
      "Tax identification number\n" + "(Código de identificación fiscal)",
  },
  FI: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    countryName: "Finland",
    priority1Identifier: "Personal identity code",
    priority2Identifier: "CONCAT",
  },
  FR: {
    code1: NationalIdentifierAndPriorityCode.CUST,
    countryName: "France",
    priority1Identifier: "CONCAT",
  },
  GB: {
    code1: NationalIdentifierAndPriorityCode.SOSE,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "United Kingdom",
    priority1Identifier: "UK National Insurance number",
    priority2Identifier: "CONCAT",
  },
  GR: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Greece",
    priority1Identifier: "10 DSS digit investor share",
    priority2Identifier: "CONCAT",
  },
  HR: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Croatia",
    priority1Identifier:
      "Personal Identification Number\n" +
      "(OIB – Osobni identifikacijski\n" +
      "broj)",
    priority2Identifier: "CONCAT",
  },
  HU: {
    code1: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Hungary",
    priority1Identifier: "CONCAT",
  },
  IE: {
    code1: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Ireland",
    priority1Identifier: "CONCAT",
  },
  IS: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    countryName: "Iceland",
    priority1Identifier: "Personal Identity Code",
  },
  //  Second Page of table
  IT: {
    code1: NationalIdentifierAndPriorityCode.TXID,
    countryName: "Italy",
    priority1Identifier: "Fiscal code\n" + "(Codice fiscale)",
  },
  LI: {
    code1: NationalIdentifierAndPriorityCode.CCPT,
    code2: NationalIdentifierAndPriorityCode.NIDN,
    code3: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Liechtenstein",
    priority1Identifier: "National Passport Number",
    priority2Identifier: "National Identity Card Number",
    priority3Identifier: "CONCAT",
  },
  LT: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CCPT,
    code3: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Lithuania",
    priority1Identifier: "Personal code(Asmens kodas)",
    priority2Identifier: "National Passport Number",
    priority3Identifier: "CONCAT",
  },
  LU: {
    code1: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Luxembourg",
    priority1Identifier: "CONCAT",
  },
  LV: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Latvia",
    priority1Identifier: "Personal code (Personas kods)",
    priority2Identifier: "CONCAT",
  },
  MT: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CCPT,
    countryName: "Malta",
    priority1Identifier: "National Identification Number",
    priority2Identifier: "National Passport Number",
  },
  NL: {
    code1: NationalIdentifierAndPriorityCode.CCPT,
    code2: NationalIdentifierAndPriorityCode.NIDN,
    code3: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Netherlands",
    priority1Identifier: "National Passport Number",
    priority2Identifier: "National identity card number",
    priority3Identifier: "CONCAT",
  },
  NO: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Norway",
    priority1Identifier: "11 digit personal id (Foedselsnummer) ",
    priority2Identifier: "CONCAT",
  },
  PL: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.TXID,
    countryName: "Poland",
    priority1Identifier: "National Identification Number\n" + "(PESEL)",
    priority2Identifier: "Tax Number (Numer identyfikacji podatkowej)",
  },
  PT: {
    code1: NationalIdentifierAndPriorityCode.TXID,
    code2: NationalIdentifierAndPriorityCode.CCPT,
    code3: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Portugal",
    priority1Identifier: "Tax number\n" + "(Número de Identificação Fiscal)",
    priority2Identifier: "National Passport Number",
    priority3Identifier: "CONCAT",
  },
  RO: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CCPT,
    code3: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Romania",
    priority1Identifier:
      "National Identification Number (Cod Numeric Personal)",
    priority2Identifier: "National Passport Number",
    priority3Identifier: "CONCAT",
  },
  SE: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Sweden",
    priority1Identifier: "Personal identity number",
    priority2Identifier: "CONCAT",
  },
  SI: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Slovenia",
    priority1Identifier:
      "Personal Identification Number\n" +
      "(EMŠO: Enotna Matična Številka\n" +
      "Občana)",
    priority2Identifier: "CONCAT",
  },
  SK: {
    code1: NationalIdentifierAndPriorityCode.NIDN,
    code2: NationalIdentifierAndPriorityCode.CCPT,
    code3: NationalIdentifierAndPriorityCode.CUST,
    countryName: "Slovakia",
    priority1Identifier: "Personal number (Rodné číslo)",
    priority2Identifier: "National Passport Number",
    priority3Identifier: "CONCAT",
  },
  default: {
    code1: NationalIdentifierAndPriorityCode.CCPT,
    code2: NationalIdentifierAndPriorityCode.CUST,
    countryName: "All other countries",
    priority1Identifier: "National Passport Number",
    priority2Identifier: "CONCAT",
  },
};

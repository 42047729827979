import { axiosConfig, BaseResponse } from "@lib/axios";
import axios, { AxiosResponse } from "axios";
import { z } from "zod";

const schema = z.object({ jwt: z.string() });

export async function renewJwt(jwt) {
  const renewTokenAxiosConfig = { ...axiosConfig };
  renewTokenAxiosConfig.headers["Authorization"] = `Bearer ${jwt}`;
  const response = await axios
    .create(renewTokenAxiosConfig)
    .post<AxiosResponse<BaseResponse>>("/renew-jwt")
    .then(({ data }) => data?.data);

  return schema.parse(response);
}

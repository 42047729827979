import { AccountType, Classification, IUserContext, KycStatus } from "../types";
import { IContractFields } from "../types/contentful";
import { offsetValue, UserTypes } from "./constants";
import routes from "./routes";
import { isClientUser, isRegistredUser } from "./rules";

export interface IGetClassProps {
  disabled?: boolean;
  color?: "success" | "white" | "warning" | "error" | "black";
  fullWidth?: boolean;
  align?: "right" | "center" | "left";
  autoHeight?: boolean;
}

export const generateUniqueId = () =>
  Date.now().toString(36) + Math.random().toString(36).substring(2);

// get the class suffixes based on component props
export const getClass = ({
  disabled,
  color,
  fullWidth,
  align,
  autoHeight,
}: IGetClassProps) => {
  let suffix = "";

  if (fullWidth) {
    suffix += "-Full";
  }

  if (autoHeight) {
    suffix += "-Auto";
  }

  if (color === "success") {
    suffix += "-Success";
  } else if (color === "white") {
    suffix += "-White";
  } else if (color === "warning") {
    suffix += "-Warning";
  } else if (color === "error") {
    suffix += "-Error";
  } else if (color === "black") {
    suffix += "-Black";
  }

  if (disabled) {
    suffix += "-Disabled";
  }

  if (align === "right") {
    suffix += "-Right";
  } else if (align === "center") {
    suffix += "-Center";
  }

  return suffix;
};

// function to capitalize the first character of a string
export const capitalize = (name = "") => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

// function to return either  the next or previous step
export const getStep = (direction, max, scrollX, refs) => {
  let position;
  for (let i = 0; i < refs.length; i++) {
    const refLeftPosition =
      direction === "left"
        ? refs[i].current.offsetLeft + offsetValue
        : refs[i].current.offsetLeft - offsetValue;

    if (scrollX < refLeftPosition) {
      position = direction === "left" ? (i <= 0 ? i : i - 1) : i <= 0 ? 1 : i;
      break;
    } else if (i === refs.length - 1 && scrollX >= max) {
      position = direction === "left" ? i - 1 : i;
      break;
    } else {
      // default to position of last item
      position = refs.length - 1;
    }
  }

  return refs[position].current;
};

/** Navigate to another url
 *  @param {string} link - the url to go to
 *  @param {boolean} newTab - if the url should be openend on a new tab
 *  @param {boolean} isDownload - if the url is download url
 */
export const navigateTo = (
  link: string,
  newTab = false,
  isDownload = false,
) => {
  const name = newTab ? "_blank" : "_self";
  const options = isDownload ? "noopener,noreferrer" : "";

  // check if link is external or relative to the site
  const external = link.charAt(0) !== "/";

  const siteLink =
    // if an external link, and no protocol appended
    external && !link.includes("https://") && !link.includes("http://")
      ? // append protocol
        `//${link}`
      : // else just use the given link
        link;

  window.open(siteLink, name, options);
};

// deep compare two objects
export const deepCompare = (object: any, object1: any): boolean => {
  if (!object || !object1) return false;
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      if (object[key] !== object1[key]) {
        return true;
      }
    }
  }
  return false;
};

// function to create a file download link given its navigatable url
export const generateDownloadLink = (url: string) => {
  return new Promise((resolve, reject) => {
    window
      .fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        resolve([URL.createObjectURL(blob), blob.type.split("/")[1]]);
      })
      .catch(() => reject("Invalid URL"));
  });
};

//AML/KYC personal statuses

export const isAmlKycCompleted = (kycStatus: KycStatus): boolean => {
  return (
    !isAmlKycInProgress(kycStatus) &&
    kycStatus !== KycStatus.GENERAL_FURTHER_DETAILS
  );
};

export const isAmlKycInProgress = (kycStatus: KycStatus): boolean => {
  const amlKycInProgressStatuses = [
    KycStatus.PERSONAL_DETAILS,
    KycStatus.PERSONAL_EMPLOYMENT_INFO,
    KycStatus.PERSONAL_FURTHER_INFO,
    KycStatus.PERSONAL_DOCUMENT_UPLOAD,
  ];
  return amlKycInProgressStatuses.includes(kycStatus);
};

export const isAmlKycPassed = (kycStatus: KycStatus): boolean => {
  const passedStatuses = [
    KycStatus.GENERAL_COMPLETED,
    KycStatus.GENERAL_UNQUALIFIED,
    KycStatus.PERSONAL_QUANTITATIVE_1,
    KycStatus.PERSONAL_QUANTITATIVE_2,
    KycStatus.PERSONAL_QUALITATIVE_1,
  ];
  return passedStatuses.includes(kycStatus);
};

export const isAmlKycFailed = (kycStatus: KycStatus): boolean => {
  const failedStatuses = [
    KycStatus.GENERAL_FAILED_TECHNICAL,
    KycStatus.GENERAL_FAILED_SAR,
  ];
  return failedStatuses.includes(kycStatus);
};

export const isRetail = (classification: Classification): boolean => {
  const retailStatuses = [
    Classification.RETAIL,
    Classification.RETAIL_PROSPECTIVE_PROFESSIONAL,
  ];
  return retailStatuses.includes(classification);
};

export const isProfessional = (classification: Classification): boolean => {
  const professionalStatuses = [
    Classification.PER_SE_PROFESSIONAL,
    Classification.ELECTIVE_PROFESSIONAL,
    Classification.ELECTIVE_ELIGIBLE_COUNTERPARTY,
    Classification.PER_SE_ELIGIBLE_COUNTERPARTY,
  ];

  return professionalStatuses.includes(classification);
};

export const isPublicOrRegisteredUser = (userType: UserTypes): boolean =>
  [UserTypes.PUBLIC_USER, UserTypes.REGISTRED_USER].includes(userType);

export const hasAccessToContracts = (
  classification: Classification,
  kyc: KycStatus,
): boolean => {
  const contractsAllowedClassifications = [
    Classification.ELECTIVE_PROFESSIONAL,
    Classification.PER_SE_PROFESSIONAL,
  ];
  return (
    contractsAllowedClassifications.includes(classification) &&
    isAmlKycPassed(kyc)
  );
};

export const isPreClassifiedUser = (classification: Classification): boolean =>
  classification === Classification.EMPTY;

export const getIdentifier = (mfa: boolean | undefined): string => {
  return mfa ? "UserWithMfa" : "UserWithoutMfa";
};

const privatePages = [
  routes.regulatedContracts,
  routes.unregulatedContracts,
  routes.dashboard,
  routes.landingCorporate,
];

export const isPrivatePages = (route) => {
  return privatePages.includes(route);
};

export const isDashboardPage = (route) => {
  return route === routes.dashboard || route === routes.landingCorporate;
};

export const toBase64 = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
};

export const date24HoursFromTimestamp = (timestamp: string): string => {
  const unixTime24hoursFromTimestamp =
    new Date(timestamp).getTime() + 1000 * 60 * 60 * 24;
  const dateArr = new Date(unixTime24hoursFromTimestamp)
    .toLocaleDateString("en-us", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
    .split(",");
  dateArr.push(
    new Date(unixTime24hoursFromTimestamp).toLocaleString().split(", ")[1],
  );
  return dateArr.join(" ");
};

export const hasOccuredLessThan24HoursAgo = (timestamp: string): boolean => {
  const timeLength =
    (new Date().getTime() - new Date(timestamp).getTime()) / (1000 * 60 * 60);
  return timeLength <= 24;
};

/**
 * Converts base64 url back to a file object
 */
export const base64toFile = (dataurl: string, filename: string): File => {
  const getMime = (arr: string[]): string => {
    const match = arr[0].match(/:(.*?);/);
    return match ? match[1] : "";
  };

  var arr = dataurl.split(","),
    mime = getMime(arr),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getUserType = ({
  jwt,
  kycStatus,
  classification,
}: Pick<IUserContext, "jwt" | "kycStatus" | "classification">) => {
  switch (true) {
    case isClientUser({ classification, jwt, kycStatus }):
      return UserTypes.CLIENT_USER;
    case isRegistredUser({ classification, jwt, kycStatus }):
      return UserTypes.REGISTRED_USER;
    default:
      return UserTypes.PUBLIC_USER;
  }
};

export const getAccountType = ({ type }: Pick<IUserContext, "type">) =>
  type === AccountType.PERSONAL ? AccountType.PERSONAL : AccountType.CORPORATE;

export const isPersonalAccountType = (type) => type === AccountType.PERSONAL;

export const isRegulatedOrUnregulatedContractsSigned = (
  signedContractIds: number[] | undefined,
  unregulatedContracts: IContractFields[],
  regulatedContracts: IContractFields[],
) => {
  if (!signedContractIds?.length) return false;

  const isUnRegulatedSigned = unregulatedContracts.every((contract) =>
    signedContractIds.includes(contract.id),
  );
  const isRegulatedSigned = regulatedContracts.every((contract) =>
    signedContractIds.includes(contract.id),
  );

  return isUnRegulatedSigned || isRegulatedSigned;
};

export const areContractsSigned = (
  signedContractIds: number[] | undefined,
  contracts: IContractFields[],
) => {
  if (!signedContractIds?.length) return false;

  const contractsSigned = contracts.every((contract) =>
    signedContractIds.includes(contract.id),
  );

  return contractsSigned;
};

export const isMobileDevice = () => {
  // check for common mobile user agents
  return Boolean(
    navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i),
  );
};

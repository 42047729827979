import { JWT_EXPIRED_ERROR } from "@component/constants";
import { logout } from "@services/api/auth/logout";
import { getEnv } from "@services/config/getEnv";
import { storage } from "@utils/storage";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { renewJwt } from "@/services/api/auth/renewJwt";
import { AceJwt } from "@/types";

import { parseJwt } from "./jwt";

export type BaseResponse<T = any> = {
  status: "OK";
  timestamp: string;
  data?: T;
};

export type BaseError = {
  status: "ERROR";
  timestamp: string;
  error: { msg: string; param?: string }[];
};

export const axiosConfig: AxiosRequestConfig = {
  baseURL: getEnv("REACT_APP_ACE_PORTAL_AUTH_SERVER"),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use(async (config) => {
  const token = storage.getToken();

  if (!token) {
    return config;
  }

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});
axiosInstance.interceptors.response.use(
  (response: AxiosResponse<BaseResponse>) => response.data?.data,
  (error: AxiosError<BaseError>) => {
    const excludeEndpoints = [
      "/start-competency-exam",
      "/submit-competency-exam",
    ];
    if (
      excludeEndpoints.includes(error.response?.config.url || "") &&
      error.response?.status === 401 &&
      !error.response.data.error
        ?.map((e) => e.msg)
        .includes(JWT_EXPIRED_ERROR) &&
      storage.getToken()
    ) {
      return error.response.data;
    }

    if (error.response?.status === 401 && storage.getToken()) logout();

    return Promise.reject(error);
  },
);

let tokenRenewalInterval: NodeJS.Timer;

export const setupTokenRenewalInterval = async () => {
  if (!tokenRenewalInterval) {
    const token = storage.getToken();

    // keep trying every 30 seconds to setup a renewal interval until a token exist.
    if (!token) {
      return setTimeout(() => setupTokenRenewalInterval(), 30000);
    }

    const { exp, iat } = parseJwt(token) as AceJwt;
    const tenMinutes = 10 * 60; // we want to trigger the renewal 10 mins before expiration.
    const jwtLifespan = exp - iat;

    tokenRenewalInterval = setInterval(async () => {
      console.log("Renewing JWT....");
      const token = storage.getToken();
      const { jwt } = await renewJwt(token);
      if (!jwt) {
        logout();
        throw new Error("JWT renewal failed");
      } else {
        console.log("Renewing JWT success");
        storage.setUser({ ...storage.getUser(), jwt: jwt });
      }
    }, (jwtLifespan - tenMinutes) * 1000);
  }

  return tokenRenewalInterval;
};

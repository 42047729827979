import { queryClient } from "@lib/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { storage } from "@utils/storage";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { setupTokenRenewalInterval } from "@/lib/axios";

import { Classification, IUserContext, KycStatus } from "../types";
import routes from "./routes";
import Loading from "./ui/common/loading/Loading";

const initialContext: IUserContext = {
  classification: Classification.EMPTY,
  clientCategorisation: null,
  country: undefined,
  created: "",
  examClassification: null,
  kycStatus: KycStatus.EMPTY,
  mfaExempt: false,
  name: "",
  setUser: () => {},
  setVisitor: (arg: any) => {},
  signOut: () => {},
  surname: "",
  tempjwt: "",
};

export const UserContext = React.createContext(initialContext);

/**
 * Code split the routes
 */

/* USER PAGES
   last parameter is for the guard rules
   of the page passed on the rules.ts functions
*/
const ConfirmPage = React.lazy(
  () => import("./ui/account-pages/account-confirm/AccountConfirmation"),
);

const MFApage = React.lazy(() => import("./ui/account-pages/mfa/MFA"));

const SetPasswordPage = React.lazy(
  () => import("./ui/account-pages/set-password/SetPassword"),
);

// const InvitationPage = React.lazy(() =>
//   import("./ui/account-pages/invite/Invite"),
// );
// const InvitationConfirmPage = React.lazy(() =>
//   import("./ui/account-pages/invite-confirm/Confirm"),
// );

const UnregulatedQuantitativePage = React.lazy(
  () =>
    import(
      "./ui/account-pages/unregulated-quantitative/UnregulatedQuantitative"
    ),
);

const DownloadPage = React.lazy(
  () => import("./ui/account-pages/download/Download"),
);

const KYCRegistrationPage = React.lazy(
  () => import("./ui/account-pages/kyc/KYCRegistration"),
);

const CorporateLandingPage = React.lazy(
  () => import("./ui/account-pages/landing-corporate/LandingCorporate"),
);

const PersonalClassificationPage = React.lazy(
  () =>
    import("./ui/account-pages/classification-personal/ClassificationPersonal"),
);

const PersonalClassificationKYC = React.lazy(
  () => import("./ui/account-pages/personal-kyc/index"),
);

const ChooseCountryPage = React.lazy(
  () => import("./ui/account-pages/choose-country"),
);
const UnregulatedContractsPage = React.lazy(
  () => import("./ui/account-pages/contracts/UnregulatedContracts"),
);
const RegulatedContractsPage = React.lazy(
  () => import("./ui/account-pages/contracts/RegulatedContracts"),
);

const AvailableServices = React.lazy(
  () => import("./ui/account-pages/available-services"),
);

const AccountTypePage = React.lazy(
  () => import("./ui/account-pages/kyc/AccountType"),
);

const UnregulatedAppropriatenessTest = React.lazy(
  () => import("./ui/account-pages/unregulated-appropriateness-test"),
);

const DocumentLibrary = React.lazy(
  () => import("./ui/general-pages/document-library/DocumentLibrary"),
);

const InitialRegistrationPage = React.lazy(
  () => import("./ui/general-pages/initial-registration"),
);

const TwoFaLoginPage = React.lazy(
  () => import("./ui/general-pages/twofa-login"),
);

const PasswordReset = React.lazy(
  () => import("./ui/general-pages/reset-password"),
);

// page for invalid routes
const Page404 = React.lazy(() => import("./ui/general-pages/404/404"));

/* END OF PAGES */

// general user preferences
const visitor = window.localStorage.getItem("ace-visitor");
const visitorPreferences = visitor
  ? JSON.parse(visitor)
  : { acceptCookies: false };

// authenticated user session details
const user = window.sessionStorage.getItem("ace-user");
const userDetails = user ? JSON.parse(user) : { jwt: "", name: "" };

export default class App extends React.Component {
  state = { ...userDetails, ...visitorPreferences };

  componentDidMount() {
    window.addEventListener("storage", this.syncContextToStorage);
    setupTokenRenewalInterval();
  }

  syncContextToStorage = () => {
    const user = storage.getUser();
    this.setState(user);
  };
  /**
   * Set visitor preferences
   *
   * @param preferences
   */
  setVisitor = (preferences) => {
    // save to local storage
    window.localStorage.setItem("ace-visitor", JSON.stringify(preferences));
    // update the state
    this.setState(preferences);
  };

  /**
   * Set user details
   *
   * @param user { name, jwt }
   */
  setUser = (user: Partial<IUserContext>) => {
    // save to session storage
    storage.setUser(user);
    // update the state
    this.setState(user);
  };

  /**
   * Sign out the current user session and redirect to homepage
   */
  signOut = () => {
    storage.clearUser();

    window.location.href = routes.home;
  };

  /**
   * Get the value for the user context
   *
   * @returns {{setUser: (function(*, *): void), signOut: (function(*, *): void)}}
   */
  getContext = () => ({
    setUser: this.setUser,
    setVisitor: this.setVisitor,
    signOut: this.signOut,
    ...this.state,
  });

  /**
   * Render the Router and User context
   *
   * @returns {*}
   */
  render = () => {
    return (
      <Suspense fallback={<Loading />}>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== "test" && <ReactQueryDevtools />}
          <UserContext.Provider value={this.getContext()}>
            <Router>
              <Routes>
                {/* Public Routes */}
                <Route
                  path={routes.home}
                  element={<InitialRegistrationPage />}
                />
                <Route path={routes.twoFaLogin} element={<TwoFaLoginPage />} />
                <Route
                  path={routes.resetPassword}
                  element={<PasswordReset />}
                />
                <Route
                  path={routes.registerConfirm}
                  element={<ConfirmPage />}
                />
                {/* Account Guarded Routes */}
                <Route path={routes.mfa} element={<MFApage />} />
                <Route
                  path={routes.setPassword}
                  element={<SetPasswordPage />}
                />
                <Route path={routes.download} element={<DownloadPage />} />
                <Route
                  path={routes.documentLibrary}
                  element={<DocumentLibrary />}
                />
                <Route
                  path={routes.unregulatedAppropriatenessTest}
                  element={<UnregulatedAppropriatenessTest />}
                />
                {/* KYC PAGES */}
                <Route
                  path={routes.accountType}
                  element={<AccountTypePage />}
                />
                <Route
                  path={routes.dashboard}
                  element={<KYCRegistrationPage />}
                />
                <Route
                  path={routes.landingCorporate}
                  element={<CorporateLandingPage />}
                />
                <Route
                  path={routes.classificationPersonal}
                  element={<PersonalClassificationPage />}
                />
                <Route
                  path={routes.personalAMLKYC}
                  element={<PersonalClassificationKYC />}
                />
                <Route
                  path={routes.chooseCountry}
                  element={<ChooseCountryPage />}
                />
                <Route
                  path={routes.unregulatedContracts}
                  element={<UnregulatedContractsPage />}
                />
                <Route
                  path={routes.regulatedContracts}
                  element={<RegulatedContractsPage />}
                />
                <Route
                  path={routes.availableServices}
                  element={<AvailableServices />}
                />
                <Route
                  path={routes.unregulatedQuantitive}
                  element={<UnregulatedQuantitativePage />}
                />

                {/* IF INVALID ROUTE */}
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Router>
          </UserContext.Provider>
        </QueryClientProvider>
      </Suspense>
    );
  };
}

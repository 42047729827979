export default {
  accountType: "/account-type",
  admit: "/admit",
  apiDocs: "/docs/api/#ace-api-spec-v1",
  availableServices: "/available-services",
  bio: "/archax/:type/:name",
  chooseCountry: "/choose-country",
  classificationCorporate: "/classification-corporate",
  classificationPersonal: "/classification-personal",
  consultationPapers: "/news/consultation-papers",
  contactUs: "/contact-us",
  custody: "/custody",
  dashboard: "/dashboard",
  documentLibrary: "/document-library",
  download: "/download",
  home: "/",
  invest: "/invest",
  investDetails: "/invest/:URLtag",
  inviteConfirm: "/invite/confirm/:confirmation",
  inviteUser: "/invite-user",
  landingCorporate: "/landing-corporate",
  login: "/login",
  mfa: "/mfa",
  news: "/news",
  newsArticle: "/article/:headline",
  newsHeadline: "/news/:headline",
  personalAMLKYC: "/personal-aml-kyc",
  privacy: "/privacy-policies",
  registerConfirm: "/register/confirm/:confirmation",
  regulatedContracts: "/contracts/mtf",
  regulatoryPermissions: "/regulatory-permissions",
  resetPassword: "/reset-password",
  setPassword: "/set-password/:confirmation",
  terms: "/terms",
  trade: "/trade",
  tradeCryptocurrencies: "/trade/cryptocurrencies",
  twoFaLogin: "/twoFaLogin",
  unregulatedAppropriatenessTest: "/unregulated-appropriateness-test",
  unregulatedContracts: "/contracts/atf",
  unregulatedQuantitive: "/unregulated-quantitative",
};

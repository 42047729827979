import "./Loading.scss";

import React from "react";

const getDate = () => {
  return new Date(Date.now());
};

const Loading = () => {
  return (
    <div className="Loading" data-testid="loading">
      <div className="Loading-Loader" />

      {getDate().getMonth() !== 11 ? (
        <div className="Loading-Logo" />
      ) : (
        <div className="Loading-Logo-Xmas" />
      )}
    </div>
  );
};

export default Loading;

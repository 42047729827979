import { ICountryFields } from "./contentful";

export interface AceJwt {
  exp: number;
  iat: number;
  primaryOrg: number;
  permissions: [[number, string[]]];
  sub: number;
  roles: string; // Deprecated: do not use this
}

export interface IOption {
  id: string;
  label: string;
}

export enum Classification {
  RETAIL = "retail",
  RETAIL_PROSPECTIVE_PROFESSIONAL = "retailProsProf",
  CORPORATE_PROSPECTIVE_PROFESSIONAL = "prosProfCorporate",
  PER_SE_PROFESSIONAL = "perSeProf",
  ELECTIVE_PROFESSIONAL = "electProf",
  PER_SE_ELIGIBLE_COUNTERPARTY = "perSeEligCounter",
  ELECTIVE_ELIGIBLE_COUNTERPARTY = "electEligCounter",
  EMPTY = "",
}

export enum KycStatus {
  CORPORATE_QUALITATIVE_1 = "cQualitativeClass1",
  GENERAL_UNQUALIFIED = "gUnqualified",
  GENERAL_FAILED_SAR = "gFailedSAR",
  GENERAL_FAILED_TECHNICAL = "gFailedTechnical",
  GENERAL_FURTHER_DETAILS = "gFurtherDetails",
  GENERAL_PENDING = "gPending",
  GENERAL_COMPLETED = "gCompleted",
  PERSONAL_QUANTITATIVE_1 = "pQuantitativeClass1", // this is deprecated, a user should never be this.
  PERSONAL_QUANTITATIVE_2 = "pQuantitativeClass2",
  PERSONAL_QUALITATIVE_1 = "pQualitativeClass1",
  PERSONAL_DETAILS = "pPersonalDetails",
  PERSONAL_EMPLOYMENT_INFO = "pEmploymentInfo",
  PERSONAL_FURTHER_INFO = "pFurtherInfo",
  PERSONAL_DOCUMENT_UPLOAD = "pDocumentUpload",
  EMPTY = "",
}

export enum PersonalKycKeys {
  DOCUMENTATION_DOCUMENT_META = "documentMeta",
  EMPLOYMENT_ANNUAL_REMUNERATION = "annualRemunerationRange",
  EMPLOYMENT_DIRECTORSHIP = "directorship",
  EMPLOYMENT_INDUSTRY = "industry",
  EMPLOYMENT_JOB_AND_POSITION = "jobTitleAndPosition",
  EMPLOYMENT_NATURE_OF_EMPLOYMENT = "natureOfEmployment",
  EMPLOYMENT_START_CURRENT_ROLE = "startDateCurrentRole",
  INFORMATION_MAIN_SOURCE_FUNDS = "mainSourceOfFunds",
  INFORMATION_RATIONALE = "rationale",
  INFORMATION_MAIN_SOURCE_OF_WEALTH = "mainSourceOfWealth",
  INFORMATION_EXPECTED_TRADING_FREQUENCY = "expectedTradingFrequency",
  INFORMATION_EXPECTED_TRADING_VOLUME = "expectedTradingVolume",
  INFORMATION_ESTIMATED_CASH_BALANCE = "estimatedCashBalance",
  INFORMATION_POLITICAL_SIGNIFICANCE = "politicalSignificance",
  INFORMATION_TAX_DOMICILE = "taxDomicile",
  INFORMATION_NATIONAL_IDENTIFIER_TYPE = "nationalIdentifierType",
  INFORMATION_NATIONAL_IDENTIFIER_NUMBER = "nationalIdentifierNumber",
  INFORMATION_NATIONAL_IDENTIFIER_EXPIRY_DATE = "nationalIdentifierExpiryDate",
  INFORMATION_FCA_AML_DIRECTIVE = "fcaAmlDirective",
  INFORMATION_FCA_AML_DIRECTIVE_CONFIRMATION = "fcaAmlDirectiveConfirmation",
  PERSONAL_CONTACT_ADDRESS = "contactAddress",
  PERSONAL_CONTACT_INFO = "contactInfo",
  PERSONAL_DATE_OF_BIRTH = "dateOfBirth",
  PERSONAL_NATIONALITY = "nationality",
  PERSONAL_GENDER = "gender",
  PERSONAL_IS_AGENT = "isAgent",
  PERSONAL_KNOWN_AS = "knownAs",
  PERSONAL_MIDDLE_NAME = "middleName",
  PERSONAL_PREVIOUS_ADDRESSES = "previousAddresses",
  PERSONAL_PREVIOUS_NAME = "previousName",
  PERSONAL_PRIMARY_ADDRESS = "primaryAddress",
  PERSONAL_REASON_FOR_DIFFERENT_CONTACT_ADDRESS = "reasonForDifferentContactAddress",
  INFORMATION_TAXPAYER_IDENTIFICATION_NUMBER = "taxpayerIdentificationNumber",
  IDENTIFIED_AS_PROFESSIONAL_MODAL = "identifiedAsProfessionalModal",
  PASSED_KYC_MODAL = "passedAMLKYCModal",
  AVAILABLE_SERVICES_PAGE_MODAL = "availableServicesPage",
}

export interface ExamRecord {
  attempts: number;
  examRating: string;
  productId: number;
  timestamp: string;
}

export interface ExamClassification {
  [examId: number]: ExamRecord;
}

export interface IUserContext {
  // Boolaen keys
  acceptCookies?: boolean; // if user has already accepted the cookies prompt, shown on first visit
  emailUpdates?: boolean; // if a logged in user has opted in to receive email updates from Archax
  investOptIn?: boolean; // if a logged in user has opted in to invest to Archax
  mfa?: boolean; // if a logged in user has already set up MFA for their account
  mfaExempt: boolean; // if a logged in user should be forced to setup mfa

  // For AML/KYC
  classification: string; // User's classification status
  kycStatus: KycStatus; // User's current kyc Status
  type?: AccountType | null; // User's account type

  // General
  email?: string;
  forename?: string;
  jwt?: string;
  name: string;
  surname: string;
  isAvailableServicesSubmitted?: boolean;
  clientCategorisation: null | {
    clientCategory: string;
    description: string;
  };
  examClassification: null | {} | ExamClassification;
  country: string | ICountryFields | null | undefined;
  tempjwt: string;
  created: string;

  setUser: (context: Partial<IUserContext>) => void;
  setVisitor: (arg: any) => void;
  signOut: () => void;
}

export interface ICountry {
  blacklisted: boolean;
  name: string;
}

export enum AccountType {
  PERSONAL = "personal",
  CORPORATE = "corporate",
}

export enum ProofOfAddressType {
  HMRC = "hmrc",
  MORTGAGE_STATEMENT = "mortgageStatement",
  RENT_CARD = "rentCard",
  SOLICITORS_LETTER = "solicitorsLetter",
  UK_DRIVING_LICENCE = "ukDrivingLicence",
  UTILITY_BILL = "utilityBill",
}

export enum Country {
  UnitedStates = "US",
  Afghanistan = "AF",
  AlandIslands = "AX",
  Albania = "AL",
  Algeria = "DZ",
  AmericanSamoa = "AS",
  Andorra = "AD",
  Angola = "AO",
  Anguilla = "AI",
  Antarctica = "AQ",
  AntiguaAndBarbuda = "AG",
  Argentina = "AR",
  Armenia = "AM",
  Aruba = "AW",
  Australia = "AU",
  Austria = "AT",
  Azerbaijan = "AZ",
  Bahamas = "BS",
  Bahrain = "BH",
  Bangladesh = "BD",
  Barbados = "BB",
  Belarus = "BY",
  Belgium = "BE",
  Belize = "BZ",
  Benin = "BJ",
  Bermuda = "BM",
  Bhutan = "BT",
  Bolivia = "BO",
  BonaireSintEustatiusSaba = "BQ",
  BosniaAndHerzegovina = "BA",
  Botswana = "BW",
  BouvetIsland = "BV",
  Brazil = "BR",
  BritishIndianOceanTerritory = "IO",
  BruneiDarussalam = "BN",
  Bulgaria = "BG",
  BurkinaFaso = "BF",
  Burundi = "BI",
  Cambodia = "KH",
  Cameroon = "CM",
  Canada = "CA",
  CapeVerde = "CV",
  CaymanIslands = "KY",
  CentralAfricanRepublic = "CF",
  Chad = "TD",
  Chile = "CL",
  China = "CN",
  ChristmasIsland = "CX",
  CocosKeelingIslands = "CC",
  Colombia = "CO",
  Comoros = "KM",
  Congo = "CG",
  CongoDemocraticRepublic = "CD",
  CookIslands = "CK",
  CostaRica = "CR",
  CoteDIvoire = "CI",
  Croatia = "HR",
  Cuba = "CU",
  Curaçao = "CW",
  Cyprus = "CY",
  CzechRepublic = "CZ",
  Denmark = "DK",
  Djibouti = "DJ",
  Dominica = "DM",
  DominicanRepublic = "DO",
  Ecuador = "EC",
  Egypt = "EG",
  ElSalvador = "SV",
  EquatorialGuinea = "GQ",
  Eritrea = "ER",
  Estonia = "EE",
  Ethiopia = "ET",
  FalklandIslands = "FK",
  FaroeIslands = "FO",
  Fiji = "FJ",
  Finland = "FI",
  France = "FR",
  FrenchGuiana = "GF",
  FrenchPolynesia = "PF",
  FrenchSouthernTerritories = "TF",
  Gabon = "GA",
  Gambia = "GM",
  Georgia = "GE",
  Germany = "DE",
  Ghana = "GH",
  Gibraltar = "GI",
  Greece = "GR",
  Greenland = "GL",
  Grenada = "GD",
  Guadeloupe = "GP",
  Guam = "GU",
  Guatemala = "GT",
  Guernsey = "GG",
  Guinea = "GN",
  GuineaBissau = "GW",
  Guyana = "GY",
  Haiti = "HT",
  HeardIslandMcdonaldIslands = "HM",
  HolySeeVaticanCityState = "VA",
  Honduras = "HN",
  HongKong = "HK",
  Hungary = "HU",
  Iceland = "IS",
  India = "IN",
  Indonesia = "ID",
  Iran = "IR",
  Iraq = "IQ",
  Ireland = "IE",
  IsleOfMan = "IM",
  Israel = "IL",
  Italy = "IT",
  Jamaica = "JM",
  Japan = "JP",
  Jersey = "JE",
  Jordan = "JO",
  Kazakhstan = "KZ",
  Kenya = "KE",
  Kiribati = "KI",
  Korea = "KR",
  KoreaDemocraticPeoplesRepublic = "KP",
  Kuwait = "KW",
  Kyrgyzstan = "KG",
  LaoPeoplesDemocraticRepublic = "LA",
  Latvia = "LV",
  Lebanon = "LB",
  Lesotho = "LS",
  Liberia = "LR",
  LibyanArabJamahiriya = "LY",
  Liechtenstein = "LI",
  Lithuania = "LT",
  Luxembourg = "LU",
  Macao = "MO",
  Macedonia = "MK",
  Madagascar = "MG",
  Malawi = "MW",
  Malaysia = "MY",
  Maldives = "MV",
  Mali = "ML",
  Malta = "MT",
  MarshallIslands = "MH",
  Martinique = "MQ",
  Mauritania = "MR",
  Mauritius = "MU",
  Mayotte = "YT",
  Mexico = "MX",
  Micronesia = "FM",
  Moldova = "MD",
  Monaco = "MC",
  Mongolia = "MN",
  Montenegro = "ME",
  Montserrat = "MS",
  Morocco = "MA",
  Mozambique = "MZ",
  Myanmar = "MM",
  Namibia = "NA",
  Nauru = "NR",
  Nepal = "NP",
  Netherlands = "NL",
  NewCaledonia = "NC",
  NewZealand = "NZ",
  Nicaragua = "NI",
  Niger = "NE",
  Nigeria = "NG",
  Niue = "NU",
  NorfolkIsland = "NF",
  NorthernMarianaIslands = "MP",
  Norway = "NO",
  Oman = "OM",
  Pakistan = "PK",
  Palau = "PW",
  PalestinianTerritory = "PS",
  Panama = "PA",
  PapuaNewGuinea = "PG",
  Paraguay = "PY",
  Peru = "PE",
  Philippines = "PH",
  Pitcairn = "PN",
  Poland = "PL",
  Portugal = "PT",
  PuertoRico = "PR",
  Qatar = "QA",
  Reunion = "RE",
  Romania = "RO",
  RussianFederation = "RU",
  Rwanda = "RW",
  SaintBarthelemy = "BL",
  SaintHelena = "SH",
  SaintKittsAndNevis = "KN",
  SaintLucia = "LC",
  SaintMartin = "MF",
  SaintPierreAndMiquelon = "PM",
  SaintVincentAndGrenadines = "VC",
  Samoa = "WS",
  SanMarino = "SM",
  SaoTomeAndPrincipe = "ST",
  SaudiArabia = "SA",
  Senegal = "SN",
  Serbia = "RS",
  Seychelles = "SC",
  SierraLeone = "SL",
  Singapore = "SG",
  SintMaarten = "SX",
  Slovakia = "SK",
  Slovenia = "SI",
  SolomonIslands = "SB",
  Somalia = "SO",
  SouthAfrica = "ZA",
  SouthGeorgiaAndSandwichIsl = "GS",
  SouthSudan = "SS",
  Spain = "ES",
  SriLanka = "LK",
  Sudan = "SD",
  Suriname = "SR",
  SvalbardAndJanMayen = "SJ",
  Swaziland = "SZ",
  Sweden = "SE",
  Switzerland = "CH",
  SyrianArabRepublic = "SY",
  Taiwan = "TW",
  Tajikistan = "TJ",
  Tanzania = "TZ",
  Thailand = "TH",
  TimorLeste = "TL",
  Togo = "TG",
  Tokelau = "TK",
  Tonga = "TO",
  TrinidadAndTobago = "TT",
  Tunisia = "TN",
  Turkey = "TR",
  Turkmenistan = "TM",
  TurksAndCaicosIslands = "TC",
  Tuvalu = "TV",
  Uganda = "UG",
  Ukraine = "UA",
  UnitedArabEmirates = "AE",
  UnitedKingdom = "GB",
  UnitedStatesOutlyingIslands = "UM",
  Uruguay = "UY",
  Uzbekistan = "UZ",
  Vanuatu = "VU",
  Venezuela = "VE",
  VietNam = "VN",
  VirginIslandsBritish = "VG",
  VirginIslandsUS = "VI",
  WallisAndFutuna = "WF",
  WesternSahara = "EH",
  Yemen = "YE",
  Zambia = "ZM",
  Zimbabwe = "ZW",
}

export type Size = "xs" | "sm" | "lg" | "2x" | "3x" | "5x" | "7x" | "10x";

export type Color =
  | "black"
  | "blue"
  | "error"
  | "pink"
  | "primary"
  | "success"
  | "teal"
  | "violet"
  | "warning"
  | "white";

export type Icon =
  | "caret-down"
  | "caret-left"
  | "caret-right"
  | "caret-up"
  | "home"
  | "bars"
  | "user"
  | "users"
  | "user-tie"
  | "user-circle"
  | "linkedin"
  | "twitter"
  | "telegram"
  | "info"
  | "pencil"
  | "upload"
  | "download"
  | "check"
  | "eye"
  | "journal"
  | "handshake"
  | "globe"
  | "newspaper"
  | "warning"
  | "times"
  | "times-circle"
  | "plus"
  | "plus-plain"
  | "print"
  | "left-arrow"
  | "right-arrow"
  | "key"
  | "star"
  | "thumbs-up"
  | "clock"
  | "signin"
  | "signout"
  | "mobile"
  | "minus"
  | "question"
  | "up-arrow"
  | "down-arrow"
  | "ellipsis"
  | "calendar"
  | "ext-link"
  | "asterisk"
  | "medium"
  | "facebook"
  | "next"
  | "down"
  | "add"
  | "subtract"
  | "exclamation"
  | "trash";

export interface IEmploymentInfoDirectorshipData {
  country: string;
  firm: string;
  registeredAddress1: string;
  registeredAddress2: string;
  registeredAddress3: string;
  registeredAddressCountry: string;
  registeredAddressPostalCode: string;
  registrationNumber: string;
}
export interface IEmploymentInfoData {
  annualRemunerationRange: string;
  natureOfEmployment: string;
  startDateCurrentRole: string;
  industry: string;
  jobTitleAndPosition: string;
  directorship?: IEmploymentInfoDirectorshipData[];
}

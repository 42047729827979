import "./index.scss";

import React from "react";
import ReactDOM from "react-dom";
import { getEnv } from "./services/config/getEnv";

import App from "./component/App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

document.addEventListener("DOMContentLoaded", function () {
  const clientRecaptchaKey = getEnv("REACT_APP_ACE_PORTAL_RECAPTCHA_KEY");
  if (clientRecaptchaKey) {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${clientRecaptchaKey}`;
    console.log(script.src);
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  } else {
    console.error("RECAPTCHA_SITE_KEY is not defined in window._env_");
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

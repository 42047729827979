import { parseJwt } from "../lib/jwt";
import {
  AccountType,
  AceJwt,
  Classification,
  ExamClassification,
  ExamRecord,
  IUserContext,
  KycStatus,
} from "../types";
import {
  KYC_COMPLETED_CORPORATE,
  KYC_COMPLETED_STATUSES,
  KYC_ROLE,
  UNREGULATED_PRODUCT_ID,
} from "./constants";
import { hasOccuredLessThan24HoursAgo } from "./utils";

// user must be signed in
export const isLoggedIn = ({ jwt }: { jwt?: string }) => {
  return !["", undefined].includes(jwt);
};

// user must be verfying 2FA
export const isVerifying2FA = ({ tempjwt }: { tempjwt?: string }) => {
  return !["", undefined].includes(tempjwt);
};

// user must be have a KYC role
export const hasKYCRole = ({ jwt }) => {
  const parsedJwt = parseJwt(jwt) as AceJwt;
  const { permissions, primaryOrg } = parsedJwt;

  const rolesOrgPair = permissions.find(
    (orgPerms) => orgPerms[0] === primaryOrg,
  );
  if (!rolesOrgPair) {
    return false;
  } else {
    return rolesOrgPair[1].includes(KYC_ROLE);
  }
};

// user must have MFA configured
export const hasMfaConfigured = (mfa: boolean | undefined) => {
  return mfa;
};

// user must be a corporate account type
export const isCorporateAccount = ({ type }) => {
  return type === AccountType.CORPORATE;
};

// user must be a verified company exchange member
export const isApprovedCorporateAccount = ({ type, kycStatus }) => {
  return (
    type === AccountType.CORPORATE &&
    KYC_COMPLETED_CORPORATE.includes(kycStatus)
  );
};

// check for Extended Invest page only shown for All gCompleted Corp users or Personal users who have opted up to professional & are gCompleted
export const canShowExtendedInvestPage = ({
  type,
  kycStatus,
  classification,
}: IUserContext) => {
  if (
    type === AccountType.CORPORATE &&
    kycStatus === KycStatus.GENERAL_COMPLETED
  ) {
    return true;
  }

  return (
    type === AccountType.PERSONAL &&
    kycStatus === KycStatus.GENERAL_COMPLETED &&
    (classification === Classification.PER_SE_PROFESSIONAL ||
      classification === Classification.ELECTIVE_PROFESSIONAL)
  );
};

export const isDoneWithKYC = (kycStatus) => {
  return [
    KycStatus.GENERAL_COMPLETED,
    KycStatus.GENERAL_FAILED_SAR,
    KycStatus.GENERAL_FAILED_TECHNICAL,
    KycStatus.GENERAL_FURTHER_DETAILS,
    KycStatus.GENERAL_PENDING,
    KycStatus.GENERAL_UNQUALIFIED,
  ].includes(kycStatus);
};

// check if a user has completed the classification step
export const isDoneWithClassification = (type, kycStatus) => {
  return type === AccountType.PERSONAL
    ? // personal accounts
      kycStatus !== KycStatus.PERSONAL_QUANTITATIVE_1 &&
        kycStatus !== KycStatus.PERSONAL_QUANTITATIVE_2 &&
        kycStatus !== KycStatus.PERSONAL_QUALITATIVE_1
    : // corporate accounts
    type === AccountType.CORPORATE
    ? kycStatus !== KycStatus.CORPORATE_QUALITATIVE_1
    : // not a valid account type
      false;
};

// determine if a user can opt in to invest
export const canInvestOptIn = ({ classification, type }: IUserContext) => {
  // user must have a valid account type
  if (type !== AccountType.PERSONAL && type !== AccountType.CORPORATE) {
    return false;
  }

  // corporate accounts that are either professional or eligible counterparty
  if (type === AccountType.CORPORATE) {
    return (
      classification === Classification.ELECTIVE_ELIGIBLE_COUNTERPARTY ||
      classification === Classification.ELECTIVE_PROFESSIONAL
    );
  }

  // else, personal accounts must at least passed classification
  return (
    classification !== Classification.EMPTY &&
    classification !== Classification.RETAIL
  );
};

export const isKYCCompleted = ({ kycStatus }) => {
  return KYC_COMPLETED_STATUSES.includes(kycStatus);
};

export const isClientUser = ({
  jwt,
  kycStatus,
  classification,
}: Pick<IUserContext, "jwt" | "kycStatus" | "classification">) => {
  const allowedClassifications = [
    Classification.ELECTIVE_PROFESSIONAL,
    Classification.PER_SE_PROFESSIONAL,
    Classification.ELECTIVE_ELIGIBLE_COUNTERPARTY,
    Classification.PER_SE_ELIGIBLE_COUNTERPARTY,
  ];

  return (
    isLoggedIn({ jwt }) &&
    kycStatus === KycStatus.GENERAL_COMPLETED &&
    allowedClassifications.includes(classification as Classification)
  );
};

export const isRegistredUser = ({
  jwt,
  kycStatus,
  classification,
}: Pick<IUserContext, "jwt" | "kycStatus" | "classification">) => {
  const allowedClassifications = [
    Classification.ELECTIVE_PROFESSIONAL,
    Classification.PER_SE_PROFESSIONAL,
    Classification.ELECTIVE_ELIGIBLE_COUNTERPARTY,
    Classification.PER_SE_ELIGIBLE_COUNTERPARTY,
  ];

  return (
    isLoggedIn({ jwt }) &&
    (kycStatus !== KycStatus.GENERAL_COMPLETED ||
      !allowedClassifications.includes(classification as Classification))
  );
};

export const hasNotDoneCategorisation = (context) =>
  !(
    context.clientCategorisation &&
    Object.values(context.clientCategorisation).length > 0
  );

export const canAccessProducts = (context) => {
  const { clientCategorisation, country, examClassification, classification } =
    context;
  const isProfessional = [
    Classification.ELECTIVE_PROFESSIONAL,
    Classification.PER_SE_PROFESSIONAL,
    Classification.ELECTIVE_ELIGIBLE_COUNTERPARTY,
    Classification.PER_SE_ELIGIBLE_COUNTERPARTY,
  ].includes(classification);
  const notUkResident = country && country.name !== "United Kingdom";

  const hasDoneCategorisation =
    clientCategorisation && Object.values(clientCategorisation).length > 0;

  const hasDoneExam =
    examClassification &&
    hasPassedExamForProduct(examClassification, UNREGULATED_PRODUCT_ID);

  if (isProfessional || notUkResident) {
    return true;
  }

  if (hasDoneCategorisation && hasDoneExam) {
    return true;
  }

  return false;
};

export const hasAttemptedExamForProduct = (examClassification, productId) => {
  const examClassificationEntries: ExamRecord[] =
    examClassification && Object.values(examClassification);
  return !!examClassificationEntries.find(
    (examClass) => examClass.productId === productId,
  );
};

export const hasPassedExamForProduct = (examClassification, productId) => {
  if (!examClassification) {
    return false;
  }

  const examClassificationEntries: ExamRecord[] =
    examClassification && Object.values(examClassification);
  return !!examClassificationEntries.find(
    (examClass) =>
      examClass.productId === productId && examClass.examRating === "pass",
  );
};

export const canTakeExamForProduct = (context) => {
  const { examClassification, country, classification, clientCategorisation } =
    context;

  // If examClassification is null they aren't eligeble.
  if (examClassification === null) {
    return false;
  }

  // FINPROM: If the exam object is empty they can take it.
  // This is really brittle, Should be index by product id not just be one empty object. Consult backend.
  if (Object.values(examClassification).length === 0) {
    return true;
  }

  const PROFESSIONAL_CLASSIFICATIONS = [
    Classification.ELECTIVE_PROFESSIONAL,
    Classification.PER_SE_PROFESSIONAL,
    Classification.ELECTIVE_ELIGIBLE_COUNTERPARTY,
    Classification.PER_SE_ELIGIBLE_COUNTERPARTY,
  ];
  const isUKResident = country?.name === "United Kingdom";
  const isProfessional = PROFESSIONAL_CLASSIFICATIONS.includes(
    classification as Classification,
  );

  const hasDoneCategorisation =
    clientCategorisation && Object.values(clientCategorisation).length > 0;
  const examRecord = Object.values(
    examClassification as ExamClassification,
  )[0] as ExamRecord;

  const { attempts, examRating, timestamp } = examRecord;
  const isInCooldown = [2, 3].includes(attempts)
    ? hasOccuredLessThan24HoursAgo(timestamp)
    : false;

  return (
    isUKResident &&
    !isProfessional &&
    hasDoneCategorisation &&
    attempts < 4 &&
    !isInCooldown &&
    examRating !== "pass"
  );
};
